/**
 * bootstrap theme application
 */

const header = document.querySelector('header')
const getScroll = () => (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0)

// menu opener
document.addEventListener('DOMContentLoaded', () => {
  const menuOpener = document.querySelector('.menu-opener')
  const body = document.querySelector('body')
  if (!header || !menuOpener) return
  menuOpener.addEventListener('click', () => {
    header.classList.toggle('menu-open')
    header.classList.remove('search-open')
    body.classList.toggle('no-scroll')

  })
})

// search opener
document.addEventListener('DOMContentLoaded', () => {
  const menuOpener = document.querySelector('.search-opener')
  if (!header || !menuOpener) return
  menuOpener.addEventListener('click', () => {
    header.classList.toggle('search-open')
    header.classList.remove('menu-open')
  })
})

document.addEventListener('DOMContentLoaded', () => {
  const searchOpener = document.querySelector('.search-opener-mobile')
  if (!searchOpener) return
  searchOpener.addEventListener('click', () => {
    header.classList.toggle('search-open')
  })
})
document.addEventListener('DOMContentLoaded', () => {
  const searchOpener = document.querySelector('.search-opener-tablet')
  if (!searchOpener) return
  searchOpener.addEventListener('click', () => {
    header.classList.toggle('search-open')
  })
})

// submenu opener
document.addEventListener('DOMContentLoaded', () => {
  const submenus = document.querySelectorAll('.menu li > .submenu-wrapper > span')
  if (!submenus) return
  submenus.forEach(span => {
    span.addEventListener('click', () => {
      //check if the parent node of the span has a class of item-open if it does remove it else add it
      span.parentNode.parentNode.classList.toggle('item-open')
    })
    //check if a click happened ouutsie the span and the parent node of the span has a class of item-open if it does remove it
    document.addEventListener('click', ({ target }) => {
      if (!target || target === span || span.parentNode.parentNode.contains(target)) return
      span.parentNode.parentNode.classList.remove('item-open')

    })
  })

})
// if there is a logout class in the header then toggle the search-open class
document.addEventListener('DOMContentLoaded', () => {
  const logout = document.querySelector('.logout')
  if (!logout) return
  header.classList.toggle('logged-in')
})

//if the window width is more than 1220px remove search-open class from header
window.addEventListener('resize', () => {
  if (window.innerWidth > 1220) {
    header.classList.remove('search-open')
  }
})
// accordions
document.addEventListener('DOMContentLoaded', () => {
  const accordionOpeners = document.querySelectorAll('.block-accordion .item [role="tab"]')
  if (!accordionOpeners) return
  accordionOpeners.forEach(opener => {
    opener.addEventListener('click', () => {
      const parent = opener.parentNode
      parent.setAttribute('aria-expanded', parent.getAttribute('aria-expanded') === 'true' ? 'false' : 'true')
    })
  })
})

// sticky header
function updateHeader() {
  if (!header) return;

  // Assuming 'getScroll' is a function you've defined to get the current scroll position.
  // You can replace 'getScroll()' with 'window.scrollY' if you're just using the vertical scroll position.
  if (window.scrollY > 50) {
    header.classList.add('sticky');
  } else {
    header.classList.remove('sticky');
  }
}

// Listen for scroll events
window.addEventListener('scroll', updateHeader);

// Ensure the header state is checked when the page is fully loaded
document.addEventListener('DOMContentLoaded', updateHeader);

const updateCssVarsForHeader = () => {
  const header = document.querySelector('header');
  if (!header) return;
  setTimeout(() => {
    const headerHeight = header.offsetHeight - 1;
    document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
  }, 200);
}
document.addEventListener('DOMContentLoaded', updateCssVarsForHeader);
window.addEventListener('resize', updateCssVarsForHeader);

// search bar category filter
document.addEventListener('DOMContentLoaded', () => {
  const searchBarCategory = document.querySelector('.search-bar .category select')
  if (!searchBarCategory) return
  searchBarCategory.addEventListener('change', () => {
    window.location.href = searchBarCategory.value
  })
})

// filter opener
document.addEventListener('click', ({ target }) => {
  if (!target || !target.matches('.filter-opener')) return
  target.closest('aside').classList.toggle('filter-open')
  if (target.closest('aside').classList.contains('filter-open')) {
    target.scrollIntoView({ behavior: 'smooth' })
  }
})

document.addEventListener('DOMContentLoaded', () => {
  // assorted code from old site
  if (document.querySelector('#gforms_confirmation_message')) {
    document.getElementById("gforms_confirmation_message").scrollIntoView();
    setTimeout(function () {
      document.querySelector('body > div > header').classList.add('hidden');
    }, 300);
    if (!document.querySelector('.download-cpd') && document.querySelector('a.button.try-again-btn')) {
      document.querySelector('a.button.try-again-btn').addEventListener('click', function (event) {
        if (!window.location.href.match(/\?retry/i)) {
          window.location = window.location.href + '?retry=1#gform';
        } else {
          window.location = window.location.href.split('?')[0] + '#gform';
        }
      });
    }
  }
  if (window.location.href.match(/\#gform$/i)) {
    document.querySelector('.full-page.type-cpd .gform_wrapper').scrollIntoView();
    setTimeout(function () {
      document.querySelector('body > div > header').classList.add('hidden');
    }, 300);
  }
  if (document.querySelector('.full-page .gform_title')) {
    var gformTitle = document.querySelector('.gform_title');
    if (gformTitle.innerText.match(/_minutes=/i)) {
      var minutesEle = document.createElement('span');
      var match = gformTitle.innerText.match(/_minutes=(.+)_/i);
      var theminute = match[1];
      minutesEle.classList.add('minutes');
      minutesEle.innerText = theminute + ' MINUTES';
      gformTitle.innerText = gformTitle.innerText.replace(/_minutes=(.+)_/i, '');
      gformTitle.append(minutesEle);
    }
  }
})
//find the search-form class and find the placeholder='Search' and replace it with 'Search the site'
document.addEventListener('DOMContentLoaded', () => {
  const searchForm = header.querySelectorAll('.search-form');
  if (!searchForm) return;
  searchForm.forEach(form => {
    form.querySelector('input').setAttribute('placeholder', 'Find fund, trust, insights')
  })
}
)




document.addEventListener('click', function(event) {
  if (!event || !event.target || !event.target.matches('.panel-search-filter-wrapper .section-title, .panel-search-filter-wrapper .section-title *')) {
    return;
  }
  const facets = event.target.closest('.panel-search-filter-wrapper').querySelector('.facetjs');
  facets.classList.toggle('is-active');
});
